<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
             <SideMenu :indexsec="9"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
           
            <form
              @submit.prevent="
                submit(
                 title_form,
                 url_form,
                 date_form,
                  file1,
                )
              "
            >
            <div class="div_EProduct">
              <p>AGREGAR VIDEO</p>
              </div>


              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Título</label>
                  <b-form-input
                  
                    v-model="title_form"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>
                 <div class="">
                  <label class="labelA color-1" for="inputA"
                    >Fecha</label
                  >
                  <b-form-input
                    v-model="date_form"
                    required
                    
                    id="inputA"
                    type="date"
                   />
                  </div>
          
                
                 
                
              </div>
              <div class="div-image Div_ImageTop  QUITLEFT">
                  <label class="labelImg color-1 EPF1_C1centrado" for="inputImg">Imagen video</label>
                  <b-form-file
                    class="EPF1_C1centrado"
                    v-model="file1"
                    id="inputImg"
                    required
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div>


            <div class="row mx-0 row-one">
               
             <div class="iframe">

                  <label class="labelifr color-1" for="inputifr">INSERTAR Iframe</label>
                  <p class="p1">Agrega el iframe proporcionado por el sitio web del cual vas a compartir el video.</p>
                  <p class="p2">El iframe usualmente se encuentra en la opción insertar del compartir.</p>
                  <b-form-input
                  
                    v-model="url_form"
                    
                    id="inputifr"
                    type="text"
                    placeholder="iframe"
                  />
                </div>
                
                 
                
              </div>


                

             

                 <label class="labelA color-1 mtrow EPF1_C1centrado">Video</label>
              <vue-editor
                  id="editor"
                  class="quill-editor"
                  v-model="url_form"
                  :editorToolbar="toolbar()"
                  >
              </vue-editor> 


           
               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El video se guardo correctamente</p>
                  </div>
                  <div class="">
                    
                      <div class="btns-dflx">
                  <b-button class="btn-modal" @click="agregarOtro()"
                      >AÑADIR OTR0</b-button
                    >
                                       
                        <b-button class="btn-modal"  @click="toEdit()">EDITAR</b-button>
                        
                        <b-button class="btn-modal" @click="toPage()">VER VIDEOS</b-button>
                     </div>

                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
        
            
          <label h2 id="prev-title" class="pvz-title color-1 EPF1_C1centrado">Previsualización</label>
              <div id="prev_quill" v-html="url_form">
              </div>

            <label id="prev-title" class="html-title color-1 EPF1_C1centrado">HTML</label>
             <div id="prev_quill">
               {{url_form}}
             </div>
           


  
          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import Header from '../../components/Header';


import { VueEditor } from "vue2-editor";

export default {
    name: "AddVideos",
    components: {
    Header,
    SideMenu,
    VueEditor,
  },
  data() {
    return {
    file1:null,
      date_form:'',
      title_form:"",
      status: "",
      msg: "",
      url_form:'',
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      added: '',
            //editor
      editorOption: { 
          modules: {
                   
                },
                contenteditable:false,
                theme: 'snow'
        }, // Elemento de configuración del editor 

    
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
    
    
  },
  computed:{
     
        url() {
      return this.$store.getters["main/baseURL"];
    },user() {            
            return this.$store.getters['admin/getIdentity'];
        },
  },
  methods: {
   
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    toPage: function () {
       this.$router.push("/videos").catch((err) => {});
    },

    toEdit: function () {
       this.$router.push("/editar/video/"+this.added.id).catch((err) => {});
    },
    submit: async function (  title, url, date, image) {
          this.status=''
          this.msg=''
        
        if(image == null){
            this.status='error'
            this.msg='Imagen no subida'
         }else if(url =='' || url ==undefined || url==null){
          this.status='error'
          this.msg='El campo de video está vacío o no es valido.'
         }else{
           if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{

            var data = new  FormData();
              
            data.append('image', image);
            data.append('title',title);
            data.append('url',url);
            data.append('datevideo',date);
            data.append('_method', 'POST');

          this.status=''
          this.msg=''
              
          let result = await this.$store.dispatch("main/addItem",  {option:'videos', item: data});
        
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.url_form=''
                 this.added = result.added
              this.showModal() 
          }

         }

        }

        
    },
    agregarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
    toolbar:function(){
      return  [
                [  'video' ], 
            ];
    }
  

  },
};
</script>

<style scoped>

.iframe{
  margin-top: 2vw;
  margin-left: 10vw;
}.iframe input{
  width: 53.4vw !important;
}


.iframe p.p1{
  color: var(--color-5);
  margin-bottom: 0vw;
  font-size: .9vw;
}

.iframe p.p2{
  color: var(--color-3);
   font-size: .5vw;
   width: 54vw;
}
</style>
